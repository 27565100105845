
.main-wrapper {
    height: 100%;
    padding: 0 20px 20px 20px;
    box-sizing: border-box;
    .table {
        height: 100%;
        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .goods-text-table {
        .answer {
            display: flex;
            align-items: center;
            img {
                width: 80px;
                height: 80px;
                margin-right: 15px;
                cursor: pointer;
            }
            .text {
                flex: 1;
                width: 1%;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
            }
        }
    }
    .link-box {
        .view-detail {
            ::v-deep .el-link--inner {
                color: #1AB163;
            }
        }
    }
    .goods-img-dialog {
        .upload-file-content {
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 445px;
            .big-img {
                width: 444px;
                height: 444px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .small-img {
                flex: 1;
                width: 1%;
                margin-left: 94px;
                img {
                    width: 80px;
                    height: 80px;
                    margin-bottom: 15px;
                    border: 2px solid transparent;
                    box-sizing: border-box;
                    &:nth-child(2n-1) {
                        margin-right: 18px;
                    }
                    &:hover {
                        cursor: pointer;
                        border: 2px solid #2DC079;
                    }
                }
                .selected {
                    border: 2px solid #2DC079;
                }
            }
        }
    }
    .goods-score-dialog {
        .upload-file-content {
            display: flex;
            flex-direction: column;
            height: 383px;
        }
    }
}
